@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');



* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    line-height: 150%;
}

a,
a:focus {
    text-decoration: none;

    outline: 0;
}


input,
textarea,
select {
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    outline: none !important;
}

a {
    color: #1974C6;
    transform: scale(1);
}

a,
button,
a:hover {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.form-control:focus {
    border-color: #202020 !important;
    outline: 0;
    box-shadow: none !important;
}

.btn:focus {
    box-shadow: none !important;

}

body {
    font-family: 'Open Sans', sans-serif;
    background: #EAEAEA;
    font-size: 16px;
}

p {
    margin: 0;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}

hr {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.section {
    padding: 40px 0;
}

/* login-page */

.login-bg {
    background-image: url('../images/login-bg.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration-form {
    background-color: #fff;
    padding: 15px 35px;
    border-radius: 10px;
    width: 50%;
    margin: auto;
    ;
    box-shadow: rgb(255 255 255 / 20%) 0px 8px 24px;
}

.login-heading {
    font-size: 28px;
    font-weight: bold;
    font-family: mulish;
    margin-top: 15px;
    margin-bottom: 20px;
}

.login-bg .form-label {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    font-family: mulish;

}

.form-control {
    border: 1px solid #cacaca;
}

.form-control::placeholder {
    color: #CACACA;
    font-weight: 500;
    font-family: mulish;
    font-size: 16px;
}

.forget-password a {
    font-weight: 600;
    color: #cacaca;
}

.site-btn {
    padding: 7px 22px;
    font-size: 16px;
    color: #fff;
    background-color: #00AEEF;
    font-weight: 700;
    font-family: mulish;
}

.site-btn:hover {
    color: #fff;
    background-color: #0083b4;
}

.form-input-box {
    position: relative;
}

.form-input-box .form-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    right: 5px;
    /* bottom: -7px; */
    color: #CACACA;
    font-size: 18px;
    top: 18px;
}

/* order-create-1 */

.page-header {
    background-color: #00AEEF;
}

.navbar-brand {
    background: #fff;
    padding: 23px !important;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-brand img {
    width: 35px;
}

.navbar .navbar-nav .nav-link {
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    font-weight: 700;
    transition: .2s ease-in-out;
}

.form-star i {
    color: #FF0000;
    font-size: 8px;
    position: absolute;
    top: 3px;
    left: 2px;
}

.form-star {
    position: relative;
}

.form-box label {
    font-weight: 600;
    color: #000;
}

.form-box {
    background: #fff;
    padding: 25px 35px;
    border-radius: 10px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15), 0px 2px 19px rgba(255, 255, 255, 0.2);
}

.form-box .form-control::placeholder {
    font-size: 13px;
    color: #CACACA;

}

.form-control {
    border: 1px solid #cacacaca;
    padding-right: 37px;
}

.heading-text-field label {
    font-size: 25px;
}


input[type=checkbox]+label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
    display: flex;
    align-items: center;
}

/* input[type=checkbox] {
    display: none;
} */

/* [type="radio"]:checked+label::before {
    content: "\2714";
    border: 0.1em solid #00aeef;
    border-radius: 0.2em;
    display: inline-block;
    width: 1em;
    height: 1em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.5em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
} 

/* input[type=checkbox]+label:active:before {
    transform: scale(0);
}

input[type=checkbox]:checked+label:before {
    background-color: #00aeef;
    border-color: #00aeef;
    color: #fff;
}

input[type=checkbox]:disabled+label:before {
    transform: scale(1);
    border-color: #aaa;
}

input[type=checkbox]:checked:disabled+label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
} */

.check-box label {
    font-size: 16px;
}


/* 
.checkbox-custom,
.radio-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative;
}

.checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #00aeef;
    content: "";
    font-family: 'FontAwesome';
    color: #00aeef;
    width: 2rem;
    height: 2rem;
    border-radius: 3px;
    margin-right: 0.5em;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
    content: "\2714";
    font-family: 'FontAwesome';
    background: rebeccapurple;
    color: #fff;
} */
/* 
.radio-custom-label {
    display: flex;
}

.radio-custom+.radio-custom-label:before {
    border-radius: 3px;
}

.radio-custom:checked+.radio-custom-label:before {
    content: "\2714";
    font-family: 'FontAwesome';
    color: #00aeef;
    width: 2rem;
    height: 2rem;
    border-radius: 3px;
    margin-right: 0.5em;
} */


.header-btn {
    background: #fff;
    color: #000;

}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #00aeef;
}

.header-profile-img img {
    width: 50px;
}

.dropdown-menu[data-bs-popper] {
    left: -25px;
}

.form-heading {
    font-size: 25px;
    font-weight: 700;
    display: inline-block;
    border: 1px solid #00aeef;
    padding: 3px 10px;
    margin-bottom: 25px;
}

.display-box .img-box {
    padding: 20px;
}

 .display-box .img-box img {
    width: 181px;
}

.display-box {
    display: inline-block;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid transparent;
}

.overflow {
    overflow: hidden;
}

.display-box:focus {
    border: 2px solid #000;
}

.display-box-heading {
    background: #00aeef;
    padding: 6px;
    font-weight: 500;
    color: #fff;
    font-family: mulish;
}

.inner-display-box {
    margin-bottom: 45px;
}

.inner-display-box-checks .display-box {
    padding: 0px !important;
}

.display-box.active {
    border: 2px solid #000;
}

.display-chek-box .check-box label {
    font-size: 30px;
    justify-content: start;
    align-items: center;
}

.display-chek-box {
    width: 100%;
}

.display-keyword img {
    width: 100%;
}

.display-keyword .img-box.active {
    border: 8px solid #00AEEF;
}

.display-keyword .img-box {
    background-image: url('../images/display-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    cursor: pointer;
    border: 8px solid transparent;
}

.display-keyword .img-box .display-keyword-heading {
    padding: 100px 0;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 40px;
}

.display-keyword-2 .img-box {
    background-image: url('../images/display-2.png');
}

.display-keyword-3 .img-box {
    background-image: url('../images/display-3.png');
}

/* order-create-4 */

.input-digits-box .form-control {
    padding: 4px 12px;
}

.input-digits-heading {
    font-weight: 700;
    color: #000;
    font-size: 15px;

}

.input-digits-label {
    font-weight: 700;
    font-size: 20px;
}

/* 
order-create-5 */
/* 
.slider-range-box{
    padding: 0 20px;
}

.range-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;

}

#range {
    -webkit-appearance: none;
    width: 100%;
}

#range:focus {
    outline: none;
}





#range::-webkit-slider-runnable-track {
    width: 100%;
    height: 2.3rem;
    cursor: pointer;
    animate: 0.2s;
    background: linear-gradient(90deg, #00C000 var(--range-progress), #CCF2CC var(--range-progress));
    border-radius: 5px;
}

#range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0.25rem solid #00C000;
    box-shadow: 0 1px 3px rgba(0, 0, 255, .3);

    background: #fff;
    cursor: pointer;
    height:60px;
    width: 15px;
    transform: translateY(calc(-35% + 8px));
}

#tooltip span {
    position: absolute;
    text-align: center;
    display: block;
    line-height: 1;
    padding: 0.125rem 0.25rem;
    color: #000;
    border-radius: 0.125rem;
    font-size: 20px;
    left: 50%;
    z-index: 9999;
    top: 16%;
    font-weight: 600;
}


.range-box .form-control {
    padding: 4px 10px;
}
.range-box {
    margin-bottom: 33px !important;
} */
#rangeValue {
    position: relative;
    display: block;
    text-align: center;
    font-size: 6em;
    color: #999;
    font-weight: 400;
}

.range {
    width: 100%;
    height: 32px;
    max-width: 812px;
    -webkit-appearance: none;
    background: #111;
    outline: none;
    border-radius: 5px;
    overflow: hidden;

}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 15px;
    border-radius: 5px;
    background: #000;
    cursor: pointer;
    border: 2px solid #333;
    box-shadow: -407px 0 0 400px #00C000;
    position: relative;
    
 
}

span.range-slider__value {
    position: absolute;
    z-index: 999;
    left: 45%;
    top: 3px;
    font-size: 18px;
    font-weight: 600;
}

.range-slider {
    position: relative;
    height: 32px;

}


.file-upload {
    background-color: #ffffff;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.file-upload-btn {
    width: 100%;
    margin: 0;
    color: #fff;
    background: #1FB264;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #15824B;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.file-upload-btn:hover {
    background: #1AA059;
    color: #ffffff;
    transition: all .2s ease;
    cursor: pointer;
}

.file-upload-btn:active {
    border: 0;
    transition: all .2s ease;
}

.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    border: 4px dashed #00AEEF;
    position: relative;
    background: #EBF9FE;
    padding: 30px 15px;
}

.image-dropping:hover {
    background-color: #00AEEF;
    border: 4px dashed #ffffff;
}

.image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #222;
}

.drag-text {
    text-align: center;
}

.drag-text h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #15824B;
    padding: 60px 0;
}

.file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    padding: 20px;
}

.remove-image {
    width: 200px;
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #b02818;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.uplaoding-icon {
    font-size: 50px;
    text-align: center;
    color: #00aeef;
}

.form-text-area-6 .form-control {
    border: 2px solid #000;
    resize: none;
}


.price-input .input-group-text {
    background-color: #00AEEF;
    color: #fff;
    font-size: 25px;
    border: 2px solid #00aeef;
}

.price-input .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 45%;
    min-width: 60%;
    border-radius: 5px;
    border: 2px solid #00aeef;
}

.price-input .form-control {
    font-size: 23px;
    font-weight: 600;
    background: #fff;
}

.price-label-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 45px 0;
}

.price-label-box .form-label {
    font-weight: 600;
    color: #000;
    font-size: 20px;
    margin: 0;
    margin-right: 20p;
    margin-right: 20px;
}

.sign-here-box .sign-here {
    border: 2px dashed #000;
    min-height: 100px;

}

.sign-here-box h6 {
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
}

.sign-here-box {
    width: 80%;
    margin: 20px auto;
}

.myform-floating .form-select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.myform-floating .form-floating>.form-control,
.form-floating>.form-select {
    height: calc(2.1rem + 2px);
    line-height: 1.25;
}

.my-keryword-form-list-colum .form-control::placeholder,
.my-keryword-form-list-colum .myform-floating .form-floating>.form-control,
.form-floating>.form-select {
    color: transparent !important;
}

.my-keryword-form-list-colum .myform-floating .form-floating>.form-control,
.form-floating>.form-select {
    color: initial !important;
}

.thank-you-bg {
    background-image: url('../images/thank-you-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    border-radius: 12px;
    padding: 25px;
}

.thanks-upper-heading h4 {
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 8px;
}

.thanks-upper-heading p {
    color: #fff;
    font-style: italic;
    font-size: 16px;
}

.thanks-bottom-headijng h4 {
    font-size: 50px;
}

.thankyou-logo {
    margin: 33px 0;
}

.form-check-input:checked[type=radio] {
    background-image: url("../images/radio.png");
}

.form-check-input:checked {
    background-color: #ffff;
    border-color: #00aeef;
    width: 2rem;
    height: 2rem;
    border-radius: 3px;
}
.form-check-input[type=radio] {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    border-radius: 3px;
    border-color: #00aeef;
    margin-right: 15px;
}

.form-check-input:focus {
    box-shadow: none;
}

.dropdown-item{
    font-weight: 500;
    transition: .3s ease-in-out;
}
.dropdown:hover .dropdown-menu {
    top: 50px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    background: #fff;
    border: 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    left: -30px;
}
.dropdown-menu {
    position: absolute;
    background: #171c24;
    top: 70px;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    display: inherit;
    left: -30px;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.Click-drop {
   
}
.main-header-dropdown {
    position: absolute;
    top: -24px;
    left: -125px;
    z-index: 9999999;
    left: 95%;
    top: 15px;
}
.header-drop-icon i{
    margin-right: 8px;
    color: #00AEEF;
}
.my-table.table>:not(:first-child) {
    border-top: 0px solid currentColor;
}
.table, .table th, .table td{
    border: 1px solid #cacacaca;
}
.my-table th{
    background-color: #000;
    color: #fff;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(255 255 255 / 5%);
}
 .btn-sm {
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    border-radius: .2rem;
}
.dash-content-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dash-content-box img {
    width: 70px;
}
.dash-inner-heading {
    font-size: 50px;
    font-weight: 600;
    color: #000;
}
.dash-content {
    /* background: linear-gradient(121deg, #00aeef 10%, #2d2e90); */
    color: #fff;
    border-radius: 10px;
    width: 38%;
    padding: 14px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #000;
}
.dashboard-box {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
    flex-wrap: wrap;
}
h5.dash-heading {
    color: #fff;
    /* border-bottom: 1px solid #000; */
    margin-bottom: 10px;
    padding: 2px 10px;
    background: #00aeef;
    border-radius: 5px;
    border: 1px solid #fff;
}
.dashboard-box .img-box {
    border-radius: 50%;
    background: linear-gradient(121deg, #00aeef 10%, #2d2e90);
    padding: 13px;
    width: 70px;
    height: auto;
    
}

.product-first-list.my-table th {
    background-color: transparent;
    color: #000 !important;
    white-space: nowrap;
    font-size: 15px;
}
.finish-box img {
    width: 32px;
}
.finish-box .img-box {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.finish-box h6{
    font-size: 14px;
    margin-left: 10px;
}
.detail-box-ul li {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
}
.detail-check img{
    width: 25px;
}
.product-second-list {
    margin-bottom: 25px;
}
.product-detail-box .detail-box-inner-heading {
    border: 1px solid #00aeef;
    display: inline-block;
    padding: 2px 6px;
    font-size: 15px;
    white-space: nowrap;
}
.total-percent {
   border-top: 1px solid #ebebeb;;
    font-size: 20px;
}
.uploaded-images-detail .img-box{
    display: flex;
    justify-content:space-around;
}
.displya-type-table th, .displya-type-table td {
    border: 0;
}
.signature-img img{
    width: 65px;
}
::selection{
    background-color: #00AEEF;
    color: #ffff;
}

.single-description-box .design-price{
    color: #00AEEF;
    font-size: 20px;
    font-weight: 700;
    margin: 8px 0;
    display: inline-block;
}

.single-description-box {
    width: 228px;
    margin-left: auto;
    text-align: center;
}
.single-description-box h5{
    font-size: 20px;
    margin-top: 10px;
}
.single-description-box .form-control{
    width: 50%;
    margin: auto;
    padding: 0.375rem 0.75rem;
    text-align: center;
}
