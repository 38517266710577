@media screen and (max-width: 1500px){
  .dropdown:hover .dropdown-menu, .dropdown-menu {
    left: -125px;
}
}
 
 
 @media screen and (max-width: 1199px){
 

}

@media screen and (max-width: 991px){

  .product-first-list {
    display: inherit;
    padding: 0;
    border: 0;
}
  .product-first-list td {
    padding: 0.5rem 0.5rem;
  }
  .table-total-td{
    border-bottom: 1px solid #cacacaca !important;
  }

  .my-table thead {
    display: none;
  }

  .my-table,
  .my-table tbody,
  .my-table tr,
  .my-table td {
    display: block;
    width: 100%;
  }
  .my-table tr {
    margin-bottom: 15px;
  }
  .my-table td {
    padding-left: 50%;
    text-align: left;
    position: relative;
  }
  .my-table tr td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 13px;
    font-weight: 700;
    text-align: left;

  }
  .my-table {
    border: 0;
  }
  .my-table tr td:last-child{
    border-bottom: 1px solid #cacaca;
  }
  .table tr td{
    border-bottom: 0;
    font-size: 14px;
    word-wrap: break-word;
  }



        .registration-form {
            width: 80%;
        }

         
    .navbar .navbar-toggler-icon {
        background-image: none;
    }
    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
      display: block;
    }
  
    .navbar-container {
      display: block;
      position: relative;
      height: 64px;
    }
  
    .navbar input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      right: 20px;
      z-index: 5;
      opacity: 0;
      cursor: pointer;
    }
  
    .navbar .hamburger-lines {
      display: block;
      height: 28px;
      width: 35px;
      position: absolute;
      top: 27px;
    right: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  
    .navbar .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #fff;
    }
  
    .navbar .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.3s ease-in-out;
    }
  
    .navbar .hamburger-lines .line2 {
      transition: transform 0.1s ease-in-out;
    }
  
    .navbar .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.3s ease-in-out;
    }
    .navbar-light .navbar-toggler {
        color: rgba(0,0,0,.5);
        border-color: rgb(45 71 91);
    }
    
   
   
  
  
  
    .navbar input[type="checkbox"]:checked ~ .header-menu-tiems {
      transform: translateX(0);
    }
  
    .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(45deg);
    }
  
    .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }
  
    .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-45deg);
    }

    .dropdown-menu[data-bs-popper] {
      left: 0;
      position: static;
  }


.sign-here-box .sign-here {
  min-height: 125px;
}


.main-header-dropdown {
  position: absolute;
  top: -24px;
  left: -125px;
  z-index: 9999999;
  left: 80%;
  top: 15px;
}
.range-slider {
  height: 38px;
}
span.range-slider__value {
  left: 45%;
  top: 6px;
}
.range {
  height: 38px;
}
.dash-inner-heading {
  font-size: 40px;
}
.dashboard-box .img-box {
  width: 60px;
}
.dash-content {
  /* background: linear-gradient(121deg, #00aeef 10%, #2d2e90); */
  width: 46%;
}
.dashboard-box {
  justify-content: space-between;
}
}

@media screen and (max-width: 767px){
    .registration-form {
        width: 100%;
    }
    .range-box{
      margin-bottom: 35px !important;
    }
   .display-box {
    margin-bottom: 20px;
    width: 100%;
}
     .my-keryword-form-list .input-digits-heading {
      font-size: 13px;
  }
  .my-keryword-form-list-colum .myform-floating .form-floating>.form-control,
  .form-floating>.form-select {
      font-size: 13px;
  }

  .thankyou-logo img, .thank-you-icon img{
    width: 100px;
  }
  .thankyou-logo {
    margin: 25px 0;
}
.thanks-upper-heading h4 {
  font-size: 22px;
}
.thanks-bottom-headijng h4 {
  font-size: 33px;
}
.main-header-dropdown {
  left: 75%;
}
.single-description-box h5 {
  font-size: 18px;
}
.order-detail-4 .display-box {
  margin-bottom: 0px;
  padding: 0;
  width: 100%;
}
.order-detail-4 .display-box .img-box img {
  width: 100%;
}
}

@media screen and (max-width: 575px){
  .pre-priced-box {
    justify-content: center;
    flex-direction: column;
}
    .login-heading {
        font-size: 25px;
    }
    .forget-password a {
        font-weight: 400;
        margin-bottom: 15px;
        display: inline-block;
        font-size: 14px;
    }
    .site-btn {
        padding: 5px 20px;
        font-size: 16px;
        font-weight: 600;
    }
    .form-box label {
    font-size: 14px !important;
}
.heading-text-field label {
  font-size: 20px;
}
.display-chek-box {
  display: flex;
  justify-content: center;
  justify-content: space-around;
}
.form-check-input[type=radio] {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 8px;
}
.form-heading {
  font-size: 16px;
}
.inner-display-box {
  margin-bottom: 40px;
}

.inner-display-box .text-end{
  text-align: center !important;
}

.display-keyword .img-box .display-keyword-heading {
  padding: 60px 0;
  font-size: 25px;
}
.mobile-none{
  display: none;
}
.my-keryword-form-list-box{
  margin-bottom: 15px;
}
.my-keryword-form-list-colum {
  margin-bottom: 10px;
}
.my-keryword-form-list-colum .myform-floating .form-floating>.form-control, .form-floating>.form-select {
  font-size: 13px;
  color: #cacaca;
}
.my-keryword-form-list-colum select option{
  color: #000;
}
.my-keryword-form-list-colum .form-control::placeholder,
.my-keryword-form-list-colum .myform-floating .form-floating>.form-control,
.form-floating>.form-select {
    color: initial !important;
}
.radio-custom:checked + .radio-custom-label:before {
  font-size: 23px;
}

.main-header-dropdown {
  left: 72%;
}
.dash-content {
  /* background: linear-gradient(121deg, #00aeef 10%, #2d2e90); */
  width: 100%;
  margin-bottom: 20px;
}
.dash-inner-heading {
  font-size: 35px;
}
.dashboard-box .img-box {
  width: 50px;
  padding: 10px;
}
.product-detail-box .detail-box-inner-heading {
  white-space: initial;
  margin-bottom: 6px;
}
.order-detail-4 .single-description-box {
  width: inherit;
}
}

@media screen and (max-width: 400px){

.display-chek-box {
  justify-content: space-between;
}
.main-header-dropdown {
  left: 64%;
}
.product-first-list {
  font-size: 90%;
}
}