.loaderapp-container
{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007bff0d;
}
.height-100
{
    height: 100px;
}
.range
{
    background:#ccf2cc;
}
.totalPercentage
{
    display: inline-block;
    padding: 2px 20px;
    border: 2px dashed #00aeef;
    font-weight: 700;
}
.keywordalert
{
    font-size: 13px;
    font-weight: 700;
}
.sign-heres
{
    display: flex;
    justify-content: center;
}
.signature
{
    width: 100%;
    height: 200;
    border: 2px dashed green !important;
    padding: 20px;
    margin: auto;
}
.signature img
{
    width: 100%;
}
.sign-heres canvas {
    aspect-ratio: inherit !important;
}
/* .sign-heres canvas
{
    width: 100%;
    height: 300px;
} */

input {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.main-header-dropdown
{
    z-index: 9;
}
.Toastify__toast-body
{
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    letter-spacing:.5px;
    font-weight: 600;
}
 .signbutton
 {
    display: flex;
    column-gap: 0px;
    justify-content: center;
 }
 .miniloader
 {
    display: flex;
    justify-content: center;
    align-items: center;
 }
 input[type="date"]
 {
    font-size: 13px;
    
 }
 .uploadPhotos
 {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    flex-wrap: wrap;
 }
 .photoslist
 {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
 }
 .photoslist img
 {
    width: 80px;
 }
 .box-header
 {
    display: flex;
    justify-content: space-between;
 }
 .backbtn
 {
    display: inline-block;
    margin-right: 10px;
 }
 .addmorebtn
 {
    display: flex;
    justify-content: end;
 }
 .priceColor
{
    color: #00aeef;
}
.termsbox
{
    display: flex;
    align-items: center;
}
.discountcolor
{
    color: #00aeef;
}
.termstextarea
{
    height: 500px !important;
}
.customphotoslist img
{
    width: 80px;
    aspect-ratio: 1/1;
    object-fit: cover;
}
.orderorigin
{
    display: flex;
    column-gap: 20px;
}
.reorder
{
    width: 20%;
}
.orderorigniwidth
{
    width: 80%;
}

.asreadycheckboxitem
{
    width: 50%;
}
.dilivrydateflex
{
    display: flex;
    align-items: end;
    column-gap: 15px;
}